import axios from "axios";
import EncryptionUtil from '../Utils/EncryptionUtil';

export const api = axios.create({    
    //baseURL: LocalHost()    
    baseURL : ServidorTeste()
    //baseURL: ServidorProducao()
  }); 

  function ServidorProducao(){
    return "https://amqjwx.aja.to:92/api"
  }

  function ServidorTeste(){
    return "https://condiweb.com.br:97/api"
  }
  
  function LocalHost(){
    return "https://localhost:7045/api"
  }

 
  export const createSession = async (login, senha, loja, name) => {  
    try {              
      const tenant = {
        ConnStr: null,
        Versao: "1.0.1",
        Name: `${name}`,
        CodCliente: `${loja}`,
        DataComputador: DataFormatada()
      };
  
      const header = {
        "Content-Type": "application/json",
        "Tenant": JSON.stringify(tenant),
      };
  
      const encryptedJSON = new EncryptionUtil();
      const encryptedJSONObj = encryptedJSON.encryptJSON(header);
      sessionStorage.setItem("header", encryptedJSONObj);
  
      api.defaults.headers.common = header;
      const response = api.post("/autoriza", { login, senha });    
      return response;
    
    } catch (error) {
      console.error("Erro na requisição:", error);
   }
  };

  function DataFormatada() {
    let data = new Date();      
    data.setUTCHours(data.getUTCHours() - 3);      
    let opcoesData = { year: 'numeric', month: '2-digit', day: '2-digit' };      
    let opcoesHorario = { hour: '2-digit', minute: '2-digit', second: '2-digit' };      
    let parteDataFormatada = new Intl.DateTimeFormat('en-US', opcoesData).format(data);
    let parteHorarioFormatada = data.toLocaleTimeString('pt-BR', opcoesHorario);    
    let compensacaoFusoHorario = '-06:00';
    let dataFormatada = `${parteDataFormatada} ${parteHorarioFormatada} ${compensacaoFusoHorario}`;
    return dataFormatada;
  }

  export const SetTenant = async() => {    
    const desencryptJSONHeader = new EncryptionUtil(); 
        const desencryptJSONObj = desencryptJSONHeader.decryptJSON(sessionStorage.getItem('header'));                
        var codCliente = JSON.parse(desencryptJSONObj.Tenant).CodCliente;                
        var name = JSON.parse(desencryptJSONObj.Tenant).Name;  

        const tenant = {      
          Versao: "1.0.1",
          Name: `${name}`,
          CodCliente: `${codCliente}`,
          DataComputador: DataFormatada()
        };

        const header = {
          "Content-Type": "application/json",
          "Tenant": JSON.stringify(tenant),
        };

      api.defaults.headers.common = header;
  }

  export default api;